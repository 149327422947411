import React, {
  Suspense, useCallback, useEffect, useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Route, Redirect, Switch, useLocation,
} from 'react-router-dom';
import { ReactQueryDevtools } from 'react-query/devtools';

import useConfig, { useConfigWithoutFetch } from 'src/hooks/useConfig';
import usePendo from 'src/hooks/usePendo';
import { useMessages } from 'src/hooks/useMessages';
import useCurrentUser from 'src/hooks/useCurrentUser';

import Loading from 'src/components/Loading';
import NotFound from 'src/screens/NotFound';
import AuthenticatedRoute, { FreshenRoute } from './AuthenticatedRoute';
import ConnectedSnackbar from './ConnectedSnackbar';
import GenericError from './GenericError';
import Logout from './Logout';

const SmartContentBuilderApp = React.lazy(() => import('src/containers/SmartContentBuilderApp'));
const Login = React.lazy(() => import('src/screens/Login'));
const FreshenPassword = React.lazy(() => import('src/containers/FreshenPassword'));
const Plop = React.lazy(() => import('src/screens/Plop'));
const TokenDelivery = React.lazy(() => import('src/containers/TokenDelivery'));
const LearnerApp = React.lazy(() => import('src/containers/ManagerUserApp'));
const ManagerApp = React.lazy(() => import('src/containers/ManagerApp'));
const TrainingCertificate = React.lazy(() => import('src/containers/TrainingCertificate'));
const LearningPlanCertificate = React.lazy(() => import('src/containers/LearningPlanCertificate'));
const JobQualificationCertificate = React.lazy(() => import('src/containers/JobQualificationCertificate'));
const Results = React.lazy(() => import('src/containers/Results'));
const RedeemPasswordToken = React.lazy(() => import('src/screens/RedeemPasswordToken'));
const Unsubscribe = React.lazy(() => import('src/screens/Unsubscribe'));
const ReportDownloader = React.lazy(() => import('src/containers/Reports/ReportDownloader'));
const ReportError = React.lazy(() => import('src/containers/Reports/ReportError'));
const CourseStreamer = React.lazy(() => import('src/containers/CourseStreamer'));

export default function App() {
  usePendo();
  const currentUser = useCurrentUser();
  const defaultLocale = useConfig('DEFAULT_LOCALE', 'en-US').replace('_', '-');
  const enableReactQueryTools = useConfigWithoutFetch('FT_ENABLE_REACT_QUERY_TOOLS', true);
  const pageLocale = useSelector(({ cookies }) => cookies.pageLocale);
  const location = useLocation();
  const { setLocale } = useMessages();
  const [drawerOpen, setDrawerOpen] = useState({
    left: false,
    right: false,
  });

  let drawer = ((drawerOpen.left) && drawerOpen.right) ? 'both' : '';
  drawer = (!drawer && (drawerOpen.left)) ? 'left' : drawer;
  drawer = (!drawer && drawerOpen.right) ? 'right' : drawer;

  useEffect(() => {
    if (!currentUser?.id && pageLocale) {
      setLocale(pageLocale || defaultLocale);
    }
  }, [currentUser?.id, defaultLocale, pageLocale, setLocale]);

  const onRightDrawerToggle = useCallback((right) => setDrawerOpen((current) => (
    {
      ...current,
      right,
    })), []);

  const onLeftDrawerToggle = useCallback((left) => setDrawerOpen((current) => (
    {
      ...current,
      left,
    })), []);
  const loginProducts = ['creator', 'coach', 'jtt', 'playbook', 'mobile', 'player', 'playrfac'].join('|');

  return (
    <>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Redirect
            from="/index.html"
            to={{
              pathname: '/login/',
              search: location.search,
            }}
          />
          <Route path="/scb/publish"><SmartContentBuilderApp /></Route>
          <Route path={`/login/:product(${loginProducts})`}><Plop /></Route>
          <Route path="/login"><Login /></Route>
          <Route path="/logout"><Logout /></Route>
          <Route
            path={`/:product(${loginProducts})/token`}
            render={() => <FreshenRoute><TokenDelivery /></FreshenRoute>}
          />
          <Route path="/redeem_password_token"><RedeemPasswordToken /></Route>
          <Route path="/unsubscribe"><Unsubscribe /></Route>
          <Route path="/email-sent/error"><ReportError /></Route>
          <Route path="/email-sent/report/:data(.+)"><ReportDownloader /></Route>
          <Route path="/stream_course/:courseId"><CourseStreamer /></Route>
          <Route
            path="/freshen_password"
            render={() => <AuthenticatedRoute><FreshenPassword /></AuthenticatedRoute>}
          />
          <Route
            path="/user"
            render={() => (
              <AuthenticatedRoute>
                <LearnerApp
                  onLeftDrawerToggle={onLeftDrawerToggle}
                  isLeftDrawerOpen={!!(drawer && drawer !== 'right')}
                />
              </AuthenticatedRoute>
            )}
          />
          <Route
            path="/manager"
            render={() => (
              <AuthenticatedRoute>
                <ManagerApp
                  onLeftDrawerToggle={onLeftDrawerToggle}
                  isLeftDrawerOpen={!!(drawer && drawer !== 'right')}
                  onRightDrawerToggle={onRightDrawerToggle}
                  isRightDrawerOpen={!!(drawer && drawer !== 'left')}
                />
              </AuthenticatedRoute>
            )}
          />
          <Route
            path="/certificate/:transcriptID/:locale*"
            render={() => <AuthenticatedRoute><TrainingCertificate /></AuthenticatedRoute>}
          />
          <Route
            path="/lp_certificate/:subscriptionId/:locale*"
            render={() => <AuthenticatedRoute><LearningPlanCertificate /></AuthenticatedRoute>}
          />
          <Route
            path="/jq_certification/:assignmentId/requirement/:requirementId/:locale*"
            render={() => <AuthenticatedRoute><JobQualificationCertificate /></AuthenticatedRoute>}
          />
          <Route
            path="/results"
            render={() => <AuthenticatedRoute><Results /></AuthenticatedRoute>}
          />
          <Redirect exact from="/" to="/login/" />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
      <GenericError />
      <ConnectedSnackbar drawerOpen={drawer} />
      {enableReactQueryTools && (<ReactQueryDevtools initialIsOpen={false} />)}
    </>
  );
}
