import React from 'react';
import { createRoot } from 'react-dom/client';

import AppProvider from 'src/containers/AppProvider';
import App from 'src/containers/App';

const container = document.getElementById('page-div');
const root = createRoot(container);

root.render(
  <AppProvider>
    <App />
  </AppProvider>,
);
