import getConfig from 'utils/getConfig';

import {
  blue,
  red,
  amber,
  green,
  lightBlue,
  lightGreen,
  grey,
  blueGrey,
  orange,
  deepOrange,
  cyan,
  deepPurple,
} from '@mui/material/colors';

const store = window.alchemyStore;
const disableTransitions = store && getConfig(store.getState().configs, 'DISABLE_UI_TRANSITIONS');
const colors = store && getConfig(store.getState().configs, 'CUSTOM_COLORS');
const customEnabled = getConfig(store?.getState().configs, 'FT_CUSTOM_COLORS', false);

export default {
  themeName: 'Manager',
  palette: {
    text: {
      primary: grey[900], //  #212121
      lightBlue: blue[100], // #bbdefb
      lightBlue2: lightBlue[100], // #b3e5fc
      lightGrey: grey[600], // #757575
      extraLightGrey: blueGrey[50], // #eceff1
      lightBlueGrey: blueGrey[200], // #b0bec5
      grey: '#9B9B9B',
      green: green[700], // #388e3c
      red: red[800], // #c62828
      blue: blue[700], // #1976d2,
      orange: deepOrange[500], // #ff5722
      white: '#fff',
      error: orange[800],
      blueHover: blue[800], // #0d47a1,
    },
    primary: {
      main: blue[700], // #1976d2
      accent: '#ffc700',
      background: '#324148',
      plank: '#475962',
    },
    secondary: {
      main: red[800], // #c62828
      accent: '#ffc700',
      background: '#324148',
      plank: '#475962',
      success: green[600],
    },
    background: {
      darkBlueGrey: blueGrey[700], // #455a64
      darkGrey: blueGrey[900], // #263238
      default: '#FFF',
      main: '#FFF',
      gray: blueGrey[50], // #ECEFF1
      mediumGray: grey[500], // #9e9e9e
      grayHover: grey[300], // #E0E0E0
      yellow: orange[50], // #FFF3E0
      orange: orange[500], // #ff9800
      extraLightBlue: blue[50], // #e3f2fd
      extraLightGreen: lightGreen[50], // #f1f8e9,
      cyan: cyan[500], // #00bcd4,
      lightGrey: grey[400], // #bdbdbd
      purple: deepPurple[700],
      green: green[500], // #4caf50
      blue: blue[700], // #1976d2,
      blue2: blue[600], // ##1e88e5,
      lightBlue: blue[100], // #bbdefb
      red: red[800], // #c62828,
      greenHover: green[700], // #388e3c
      blueHover: blue[800], // #1565c0,
      redHover: red[900], // #b71c1c,
      disabledGrey: blueGrey[100], // #cfd8dc
      lightGreen: green[300], // #81c784
      intertekGrey: '#474E54',
      lightOrange: orange[300], // #FFB74D
      lightBlueGrey: blueGrey[200], // #b0bec5
      mediumBlue: lightBlue[900], // #01579b
    },
    color: {
      deepOrange: deepOrange[500], // #ff5722
      borderColor: '#979797',
    },
  },
  custom: {
    primary: {
      accent: colors?.primary_accent,
      background: colors?.primary_background,
      icon: colors?.primary_icon,
      plank: colors?.primary_plank,
      text: colors?.primary_text,
    },
    secondary: {
      accent: colors?.secondary_accent,
      background: colors?.secondary_background,
      plank: colors?.secondary_plank,
      text: colors?.secondary_text,
    },
    tertiary: {
      background: colors?.tertiary_background,
      text: colors?.tertiary_text,
    },
  },
  customEnabled,
  typography: {
    title: {
      fontWeight: 400,
    },
    subtitle: {
      fontWeight: 500,
    },
    subtitle1: {
      fontWeight: 500,
    },
  },
  status: {
    warning: amber[500],
    success: green[900],
    failure: orange[900],
  },
  ...(disableTransitions ? { transitions: { create: () => 'none' } } : {}),

  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          background: '#FFF',
          fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
          fontSize: '14px',
          height: '100%',
          width: '100%',
          lineHeight: '1.43rem',
          letterSpacing: '0.01071rem',
        },
        html: {
          fontSize: '14px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        size: 'small',
        margin: 'normal',
      },
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '1.7rem',
        },
      },
    },
    MuiSnackbar: {
      anchorOrigin: {
        '@media (min-width: 600px)': {
          bottom: '80px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontSize: '14px',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          '&.MuiFormLabel-root.Mui-error': {
            color: red[900],
          },
        },
        formControl: {
          fontSize: '14px',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
        formControl: {
          fontSize: '14px',
        },
        input: {
          fontSize: '14px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          '&.MuiFormLabel-root.Mui-error': {
            color: red[900],
          },
        },
        formControl: {
          fontSize: '14px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          lineHeight: '18px',
          fontSize: '12px',
          '&.Mui-error': {
            color: red[900],
            marginLeft: 0,
          },
        },
      },
    },
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#095DB1',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        contained: {
          backgroundColor: blueGrey[50],
          color: grey[900],
          '&:hover': {
            backgroundColor: grey[300],
          },
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
        contained: {
          backgroundColor: blueGrey[50],
          color: grey[900],
          '&:hover': {
            backgroundColor: grey[300],
          },
        },
      },
    },
  },
};
