import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useLocation, useParams } from 'react-router-dom';
import useCurrentUser from 'src/hooks/useCurrentUser';

export function FreshenRoute({ children = null }) {
  const location = useLocation();
  const { needs_freshening: needsFreshening } = useCurrentUser();

  if (!location.pathname.includes('freshen_password') && (needsFreshening?.length || needsFreshening === true)) {
    return (
      <Redirect
        to={{
          pathname: '/freshen_password/',
          state: {
            nextPathname: location.search ? location.pathname + location.search : location.pathname,
          },
        }}
      />
    );
  }

  return children;
}

FreshenRoute.propTypes = {
  children: PropTypes.node,
};

export default function AuthenticatedRoute({ children = null }) {
  const location = useLocation();
  // Need to handle the token pages which have custom login pages
  const { product } = useParams();
  const { isLoggedIn } = useCurrentUser();

  if (!isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: `/login/${product || ''}`,
          state: {
            nextPathname: location.search ? location.pathname + location.search : location.pathname,
          },
        }}
      />
    );
  }

  return <FreshenRoute>{children}</FreshenRoute>;
}

AuthenticatedRoute.propTypes = {
  children: PropTypes.node,
};
